import styled from 'styled-components'
import { Link } from 'react-router-dom'

/* style */

/* style */

/* function */
const getMenuModifiedStyled = ({isactive, mode}) => {
  let stylesTab = ``
  if(isactive) stylesTab += `
    color: #fc5c64;
    font-weight: 700;

    &::after {
      content: '';
      position: absolute;
      background-color: #fc5c64;
      left: 0;
      height: 2px;
      bottom: -1px;
      right: 0;
      width: auto;
    }
  `
  return stylesTab
}

const getTitleContainerStyled = ({isRequired}) => {
  let stylesTab = ``
  if(isRequired) stylesTab += `
    &::after {
      margin-left: 4px;
      content: '*';
      color: #fc5c64;
    }
  `
  return stylesTab
}

/* function */

/* component */
export const AdminContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const NavContainer = styled.div`
  display: inline-grid;
  margin: 0;
  padding: 0;
  grid-auto-flow: column;
`
export const LinkContainer = styled(Link)`
  position: relative;
  padding: 15px 30px;
  font-weight: 400;
  color: #2c2c2c;
  text-align: center;
  list-style: none;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  display: block;
  text-decoration: none;
  ${getMenuModifiedStyled}
`
export const LayoutNavContainer = styled.div`
  width: 100%;
  background-color: #fff;
`
export const LayoutContentContainer = styled.div`
  margin-top: 5px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  overflow: auto;
  height: calc(100vh - 120px);
`
export const ActionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  font-weight: 600;
`
export const SortingContainer = styled.span`
  position: relative;
  cursor: pointer;

  > i {
    position: absolute;
    top: 0;
    right: -20px;
    width: 10px;
    height: 10px;
  }
`
export const SearchTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 4fr 3fr 2fr 2fr 2fr 2fr;
  grid-gap: 10px;
`
export const ListBodyContainer = styled.div`
  display: grid;
  padding: 20px 40px;
  grid-gap: 15px;
`
export const ListContainer = styled.div`  
  display: grid;
  width: 400px;
  grid-template-columns: 4fr;
  align-items: center;
  grid-gap: 20px;
`
export const ListTitleContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  ${getTitleContainerStyled}
`
export const ListContentContainer = styled.div`
  position: relative;
`
export const SearchDropdownContainer = styled.div`
  display: none;
  position: absolute;
  top: 35px;
  left: 0;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  max-height: 200px;
  z-index: 1;

  &.is-active {
    display: block;
  }
`
export const DropdownListContainer = styled.div`
  max-height: 200px;
  padding: 5px 0;
  overflow: auto;
  cursor: pointer;
  
  > div {
    padding: 5px 10px;
  }
`
export const DropdownButtonContainer = styled.div`
  padding: 10px;
  background-color: #fc5c64;
  text-align: center;
  color: #fff;
  cursor: pointer;
`
/* component */