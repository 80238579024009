import styled from 'styled-components'

/* function */
const getDropDownContainerModifiedStyled = (props) => {
  const axTop = (props.top) ? (parseInt(props.top)+5)+'px' : '40px'
  let modifiedStyled = ""

  modifiedStyled = `
    @keyframes droptodown {
      90% { top: ${axTop} }
      100% { top: ${props.top || '35px'} }
    }
    @keyframes droptoup {
      50% { bottom: -100px }
      50% { bottom: -50px }
      100% { bottom: 0px }
    }
    @keyframes dropgodown {
      90% { bottom: ${axTop} }
      100% { bottom: ${props.bottom || '35px'} }
    }
    @keyframse dropgoup {
      50% { top: -100px }
      50% { top: -50px }
      100% { top: 0px }
    }
    position: relative;
    & ${DropDownWrapper} { display: none;}
    &.is-shown {
      & ${DropDownControl} button { border-color: var(--main-button-border-color); }
      @media only screen and (max-width: 767px) {
        & ${DropDownWrapper} { 
          display: grid; 
          left: 0; 
          ${props.placement === 'top' ? 
            'animation: dropgoup .075s' : 'animation: droptoup .075s'
          }
          bottom:0; 
          overflow-x: hidden; 
        }
        & ${DropDownBody} {
          ._refFilterDropdown a { text-align: left !important; }
        }
      }
      @media only screen and (min-width: 768px) {
        & ${DropDownWrapper} { 
          display: grid; 
          ${props.placement === 'top' ? 
            `animation: dropgodown .075s linear; bottom: ${props.bottom || '35px'};` : `animation: droptodown .075s linear; top: ${props.top || '35px'}`
          }
          left: ${props.alias === "_dropdownFilterContainer" ? '100%;': props.openPosition === 'left' ? '0' : 'auto'}
        }
      }
      @media only screen and (min-width: 1024px) {
        & ${DropDownWrapper} { left: ${props.alias === "_dropdownFilterContainer" ? '30%': props.openPosition === 'left' ? '0' : 'auto'} }
      }
      @media only screen and (min-width: 1280px) {
        & ${DropDownWrapper} { left: ${props.alias === "_dropdownFilterContainer" ? '0': props.openPosition === 'left' ? '0' : 'auto'} }
      }
    }
  `

  if(props.width) modifiedStyled += `width: ${props.width}`
  if(props.minWidth) modifiedStyled += `min-width: ${props.minWidth}`

  if(props.area === 'mobile') modifiedStyled += `
    @media (min-width: 320px) and (max-width: 480px) {
      ${DropDownWrapper} { 
        width: 100%;
        align-content: start;
        z-index: 88;
      }
    }
  `
  
  return modifiedStyled
}
const getDropDownWrapperModifiedStyled = props => {
  let modifiedStyled = ""
  if(props.width) modifiedStyled += `width: ${props.width};`
  else modifiedStyled +=`
    width: auto;
    white-space: nowrap;
    ${DropDownBody} {
      overflow: unset;
    }
  `
  if(props.openPosition === 'left') modifiedStyled += `
    left: 0;
    right: auto;
  `
  else if(props.openPosition === 'center') modifiedStyled += `
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  `
  if(props.placement === 'top' && props.bottom) modifiedStyled += `top: unset; bottom: ${props.bottom}`
  else if(props.top) modifiedStyled += `top: ${props.top};`
  return modifiedStyled
}
const getDropDownActionModifiedStyled = props => {
  let modifiedStyled = `justify-self: end;`
  if(props.position === "middle") modifiedStyled += `width: 100%; text-align: center;`
  else if(props.position === "left") modifiedStyled += `width: 100%;`
  else if(props.position === "end") modifiedStyled += `width: 100%; text-align: end`
  return modifiedStyled
}

/* function */

/* component */
export const DropDownWrapper = styled.section`
  position: fixed; 
  width: 100vw; 
  border: 1px solid #E3E3E3;
  background: #FFFFFF;
  z-index: 102;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 10px 10px 0px 0px;
  > div, > section {
    padding: 1em;
  }
  @media only screen and (min-width: 768px) {
    position: absolute; 
    min-width: 110px;
    width: 100vw;
    top: 40px;
    right: 0;
    z-index: 10;
    border-radius: 10px;
    ${getDropDownWrapperModifiedStyled}
    > div, > section {
      padding: .8em 1em;
    }
  }
`
export const DropDownOverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0,0,0,.1);
  display: none;
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
`
export const DropDownControl = styled.section`
  display: block;
`
export const DropdownControlWrapper = styled.div`
  ._refDropdownControl {
    display: none;
  }
  ._refDropdownControlMobile {
    display: block;
  }
  @media only screen and (min-width: 1024px) {
    ._refDropdownControl {
      display: block;
    }
    ._refDropdownControlMobile {
      display: none;
    }
  }
`
export const DropDownContainer = styled.section`
  position: relative;
  @media only screen and (min-width: 1024px) {
    &._refContainerhover:hover {
      ${DropDownWrapper} {
        display: grid;
        animation: droptodown .075s linear;
        top: '35px';
      }
    }
  }
  ${getDropDownContainerModifiedStyled}
`
export const DropDownTitle = styled.div`
  font-weight: 600;
  border-bottom: 1px solid var(--main-border-color);
`
export const DropDownSubTitle = styled.p`
  color: var(--sub-color);
  font-weight: 100;
  margin-top: 4px;
  margin-bottom: 0;
`
export const DropDownBody = styled.section`
  overflow-y: auto;
  max-height: 75vh;
  overflow-x: hidden;
  @media (max-width: 767px) {
    max-height: 65vh;
  }
`
export const DropDownAction = styled.section`
  border-top: 1px solid var(--main-border-color);
  ${getDropDownActionModifiedStyled}
`;
/* component */