import React from 'react'
import { useQuery } from '@apollo/client'

import { ContentLoaderTableOnly } from '../../../components/loader/loader.component'
import TableComponent from '../../../components/table/table.component'
import LabelComponent from '../../../components/label/label.component'
import LinkComponent from '../../../components/link/link.component'

import { ActionContainer, SortingContainer } from '../dashboard.styles'

import { GET_TODAY_ATTENDANCE_LIST } from '../../../config/graphql'

const TodayClassAttendance = () => {
  const { loading, data } = useQuery(GET_TODAY_ATTENDANCE_LIST);
  const dataAttendance = data?.todayClassroomAttendances || [];

  return (
    (loading && !dataAttendance.length) ? <ContentLoaderTableOnly />
    :
    <div style={{fontSize: '.5em'}}>
      <TableComponent mode='custom' template='1fr 2fr 2fr 3fr 3fr'>
        <div aria-label='head'>
          <div>
            <SortingContainer>No</SortingContainer>
          </div>
          <div>
            <SortingContainer>Kelas</SortingContainer>
          </div>
          <div>
            <SortingContainer>Hadir</SortingContainer>
          </div>
          <div>
            <SortingContainer>Belum Absen</SortingContainer>
          </div>
          <div>
          </div>
        </div>
        <div aria-label='body'>
        {dataAttendance.map((attendance, idx) => {
          let studentAttendances = attendance.studentAttendances;

          // int present = attendance.studentAttendances
          //     .map((sa) => sa.status.name == 'Hadir' ? 1 : 0)
          //     .reduce((value, element) => value + element);
          // int late = attendance.studentAttendances
          //     .map((sa) => sa.status.name == 'Telat' ? 1 : 0)
          //     .reduce((value, element) => value + element);
          // int absent = attendance.studentAttendances
          //     .map((sa) => (sa.status.name == 'Absen' || sa.status.name == 'Belum Absen') ? 1 : 0)
          //     .reduce((value, element) => value + element);
          let present = studentAttendances
            .map((sa) => sa.status.name == 'Hadir' ? 1 : 0)
            .reduce((value, element) => value + element, 0);
          let late = studentAttendances
              .map((sa) => sa.status.name == 'Telat' ? 1 : 0)
              .reduce((value, element) => value + element, 0);
          let absent = studentAttendances
              .map((sa) => (sa.status.name == 'Absen' || sa.status.name == 'Belum Absen') ? 1 : 0)
              .reduce((value, element) => value + element, 0);

          let siswa = '';
          if ((absent > 0 || late > 0) && present > 0) {
            // Hadir
            siswa += `${present} Hadir`;
          }
          if (late > 0) {
            // Telat
            siswa += `, ${late} Telat `;

          }
          if (absent > 0) {
            // Absen
            siswa += `, ${absent} Absen `;
          }
          if (absent == 0 && late == 0) {
            // Semua Hadir
            siswa += 'Semua Hadir'
          }

          let date = new Date();
          let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

          return (
            <div key={idx}>
              <div>
                <LabelComponent mode='tagredmobile'>NO</LabelComponent>
                {idx + 1}
              </div>
              <div>
                <LabelComponent mode='tagredmobile'>Kelas</LabelComponent>
                {attendance.classroom.name}
              </div>
              <div>
                <LabelComponent mode='tagredmobile'>Hadir</LabelComponent>
                { present }
              </div>
              <div>
                <LabelComponent mode='tagredmobile'>Belum Absen</LabelComponent>
                { absent }
              </div>
              <div>
                <ActionContainer>
                  <LinkComponent  to={`/dashboard/attendance#startDate=${currentDate}&endDate=${currentDate}&classroomId=${attendance.classroom.id}`} mode='clearlink'>Lihat</LinkComponent>
                  {/* <LinkComponent to='#' mode='clearlink'>Calculate</LinkComponent> */}
                </ActionContainer>
              </div>
            </div>
          )
          })}
        </div>
      </TableComponent>
    </div>
  )
}

export default TodayClassAttendance;
