import React, { Component } from 'react'

import { PopupViewScores } from '../../pages/admin/popups/view-scores'
import { PopupCreateStudentAttendance } from '../../pages/admin/popups/create-student-attendance'

import { Popup } from './popup.styles'
class PopupContainer extends Component {
  render() {
    console.log('hello world')
    console.log(this.props.mode)
    console.log(this.props)
    let popupEl
    switch(this.props.mode){
      case 'view-scores':
        popupEl = <PopupViewScores {...this.props} body='clear' width='800px'/>
        break
      default:
        popupEl = <PopupCreateStudentAttendance {...this.props} body='clear' width='800px'/>
        break
    }
    return (
      <Popup id='popup'>
        {popupEl}
      </Popup>
    )
  }
}

export default PopupContainer