import React from 'react'
import { useQuery } from '@apollo/client'
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, LabelList, Legend } from 'recharts';

import { GET_ATTENDANCE_LIST } from '../../../config/graphql'

// const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];
// Sample data
// const data = [
//     {tanggal: '23/1', hadir: 110, absen: 82},
//     {tanggal: '24/1', hadir: 68, absen: 124},
//     {tanggal: '25/1', hadir: 129, absen: 63},
//     {tanggal: '26/1', hadir: 150, absen: 42},
//     {tanggal: '27/1', hadir: 145, absen: 47},
//     {tanggal: '30/1', hadir: 146, absen: 46},
//     {tanggal: '31/1', hadir: 149, absen: 43},
// ];

const labels = {
  hadir: 'Hadir',
  absen:'Absen'
};

const legendFormatter = (value,entry,index) => labels[value];

const AttendanceChart = () => {
  const date = new Date();
  let prev = new Date();
  prev.setDate(date.getDate() - 7)

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  let prevDate = `${prev.getFullYear()}-${prev.getMonth() + 1}-${prev.getDate()}`;

  const { loading, data } = useQuery(GET_ATTENDANCE_LIST, { variables: { startDate: prevDate, endDate: currentDate} });
  const dataAttendance = data?.classroomAttendances || [];

  let chartRaw = dataAttendance.reduce((total, current) => {
    let currentDate = new Date(current.date)
    let key = `${currentDate.getDate()}/${currentDate.getMonth() + 1}`

    let studentAttendances = current.studentAttendances;
    let present = studentAttendances
      .map((sa) => sa.status.name == 'Hadir' ? 1 : 0)
      .reduce((value, element) => value + element, 0);
    let late = studentAttendances
        .map((sa) => sa.status.name == 'Telat' ? 1 : 0)
        .reduce((value, element) => value + element, 0);
    let absent = studentAttendances
        .map((sa) => (sa.status.name == 'Absen' || sa.status.name == 'Belum Absen') ? 1 : 0)
        .reduce((value, element) => value + element, 0);
    
    if(total[key] == null) {
      total[key] = { hadir: 0, telat: 0, absen: 0 }
    } else {
      total[key]['hadir'] += present;
      total[key]['telat'] += late;
      total[key]['absen'] += absent;
      return total;
    }
    return total;
  }, {});

  let chart = Object.keys(chartRaw).map((key) => {
    let row = chartRaw[key]
    return {tanggal: key, hadir: row['hadir'], absen: row['absen']}
  })
  return <ResponsiveContainer width='100%'>
    <BarChart data={chart} margin={{top: 50, right: 0, left: 0, bottom: 0}}>
      <XAxis dataKey="tanggal"/>
      <YAxis/>
      <Bar dataKey="hadir" fill="#6fc276" />
      <Bar dataKey="absen" fill="#fc5c64" />
      <Legend formatter={legendFormatter} />
    </BarChart>
  </ResponsiveContainer>
}

export default AttendanceChart;
