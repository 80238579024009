import styled, { css } from 'styled-components'

export const SingleLayoutContainer = styled.section`
  display: grid;
  grid-template-areas:  'header'
                    'content';
  font: 400 88%/1.5em Proxima Nova,sans-serif;
  letter-spacing: normal;
  line-height: normal;
  color: #2c2c2c;
`
export const SingleLayoutBody = styled.section`
  display: grid;
  background-color: #f7f7f7;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
`
export const SingleLayoutWrapper = styled.article`
  padding: 5px 5px;
  width: 100%;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    "sidenav header"
    "sidenav main"
    "sidenav footer";
  height: 100vh;
`

const flex = css `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  // background-color: #648ca6;
`

export const GridHeader = styled.header`
  grid-area: header;
  // background-color: #648ca6;
  border-bottom: 2px solid rgb(245, 245, 245);

  ${flex}
`

export const GridSidenav = styled.aside`
  grid-area: sidenav;
  display: flex; /* Will be hidden on mobile */
  flex-direction: column;
  grid-area: sidenav;
  border-right: 2px solid rgb(245, 245, 245);
`

export const GridSidenavList = styled.main`
  padding: 0;
  list-style-type: none;
`

export const GridSidenavListItem = styled.main`
  padding: 5px 20px 20px 40px;
  color: rgb(50,50,50);

  :hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
`

export const GridMain = styled.main`
  grid-area: main;
  padding: 60px;
`

export const GridMainHeader = styled.main`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: 150px; /* Force our height since we don't have actual content yet */
  background-color: #e3e4e6;
  color: slategray;
`


export const GridFooter = styled.footer`
  grid-area: footer;
  border-top: 2px solid rgb(245, 245, 245);

  ${flex}
`