import React from 'react'
import { useQuery } from '@apollo/client'

import { ContentLoaderTableOnly } from '../../../components/loader/loader.component'
import LinkComponent from '../../../components/link/link.component'
import { BoxNumber, BoxSubtitle  } from '../dashboard.styles'

import { GET_TODAY_ATTENDANCE_LIST } from '../../../config/graphql'

const TodayTotalAttendance = () => {
  const { loading, data } = useQuery(GET_TODAY_ATTENDANCE_LIST);
  const dataAttendance = data?.todayClassroomAttendances || [];

  if(loading && !dataAttendance.length) {
    return <ContentLoaderTableOnly />;
  } else {
    let total = dataAttendance.map((attendance, idx) => {
      let studentAttendances = attendance.studentAttendances || [];
      let present = studentAttendances
        .map((sa) => sa.status.name == 'Hadir' ? 1 : 0)
        .reduce((value, element) => value + element, 0);
      let late = studentAttendances
          .map((sa) => sa.status.name == 'Telat' ? 1 : 0)
          .reduce((value, element) => value + element, 0);
      let absent = studentAttendances
          .map((sa) => (sa.status.name == 'Absen' || sa.status.name == 'Belum Absen') ? 1 : 0)
          .reduce((value, element) => value + element, 0);
      
      return { present: present, late: late, absent: absent}
    }).reduce((r, n) => {
      return {
        present: r['present'] + n['present'],
        late: r['late'] + n['late'],
        absent: r['absent'] + n['absent']
      }
    });

    let date = new Date();
    let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return <div style={{textAlign: 'center'}}>
      <div style={{display: 'inline-block', width: '33%', fontSize: '0.7em'}}>
        <LinkComponent  to={`/dashboard/attendance#startDate=${currentDate}&endDate=${currentDate}&statusId=3`} mode='clearlink'>
          <BoxNumber>{total['absent']}</BoxNumber>
        </LinkComponent>
        <BoxSubtitle>Belum Absen</BoxSubtitle>
      </div>
      <div style={{display: 'inline-block', width: '33%', fontSize: '0.7em'}}>
        <LinkComponent  to={`/dashboard/attendance#startDate=${currentDate}&endDate=${currentDate}&statusId=3`} mode='clearlink'>
          <BoxNumber>{total['present']}</BoxNumber>
        </LinkComponent>
        <BoxSubtitle>Hadir</BoxSubtitle>
      </div>
      <div style={{display: 'inline-block', width: '33%', fontSize: '0.7em'}}>
        <BoxNumber>-</BoxNumber>
        <BoxSubtitle>Telat</BoxSubtitle>
      </div>
    </div>
  }
}

export default TodayTotalAttendance;
