import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { CheckboxContainer, InputContainer, CheckmarkContainer } from '../checkbox/checkbox.styles'

const CheckboxComponent = ({ id, name, children, handleChange, handleClick, mode, value, flow, checked, extendKey, extendClass, isDisabled, dataQaId,reset, setReset, ...props }) => {
  const [ checkboxValue, setCheckboxValue ] = useState(false)
  const [ modCheckboxValue, setModCheckboxValue ] = useState(false) 
  const checkboxRef = React.createRef()
  let extendClassName = extendClass || ''

  useEffect(() => {
    evModChecked()
  })

  useEffect(() => {
    if(reset) {
      setCheckboxValue(false)
      setModCheckboxValue(false)
      setReset(false)
    }
  }, [reset, setReset])

  const evModChecked = () => {
    const el = (checkboxRef && checkboxRef.current) ? checkboxRef.current :  null
    let checkedValueRef = false
    if(el && el.firstElementChild) checkedValueRef = el.firstElementChild.classList.contains('checked')

    const _checked = (checked !== undefined) ? (checked ? true: false) : checkedValueRef
    if(modCheckboxValue !== _checked) {
      setCheckboxValue(_checked)
      setModCheckboxValue(_checked)
      extendClassName = (_checked) ? ' checked ' : ' '
    }
  }

  const onChange = (e) => {
    const value = e.target.checked 
    setCheckboxValue(value)
    if (handleChange && typeof(handleChange) == "function") handleChange(e)
  }

  return (
    <CheckboxContainer flow={flow} {...props} ref={checkboxRef}>
      {children}
      <InputContainer id={id} type='checkbox' className={` ${extendClassName} ${checkboxValue ? 'checked': ''}`} disabled={isDisabled} checked={checkboxValue} onChange={onChange} data-key={extendKey} name={name} value={value} onClick={handleClick} {...props} />
      <CheckmarkContainer mode={mode} isError={props.isError} data-qa-id={dataQaId}></CheckmarkContainer>
    </CheckboxContainer>
  )
}

CheckboxComponent.defaultProps = {
  isDisabled: false
}

CheckboxComponent.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
  mode: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  checked: PropTypes.bool,
  extendKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isDisabled: PropTypes.bool,
  reset: PropTypes.bool
}

export default CheckboxComponent