import React from 'react'
import { GridExplicit, Box, BoxNumber, BoxSubtitle  } from '../dashboard.styles'
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, LabelList, Legend } from 'recharts';
import TodayClassAttendance from '../components/today_class_attendance';
import TodayTotalAttendance from '../components/today_total_attendance';
import AttendanceChart from '../components/attendance_chart';

import { useQuery } from '@apollo/client'
import { GET_CLASSROOM_LIST } from '../../../config/graphql'

// const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];
// Sample data
const data = [
  {tanggal: '23/1', hadir: 110, absen: 82},
  {tanggal: '24/1', hadir: 68, absen: 124},
  {tanggal: '25/1', hadir: 129, absen: 63},
  {tanggal: '26/1', hadir: 150, absen: 42},
  {tanggal: '27/1', hadir: 145, absen: 47},
  {tanggal: '30/1', hadir: 146, absen: 46},
  {tanggal: '31/1', hadir: 149, absen: 43},
];

const labels = {
  hadir: 'Hadir',
  absen:'Absen'
};

const legendFormatter = (value,entry,index) => labels[value];

const DashboardMain = () => {
  const { loading, data: dataClassrooms } = useQuery(GET_CLASSROOM_LIST);
  const classrooms = dataClassrooms?.classrooms || [];
  const studentsCount = classrooms.length == 0 ? '' : classrooms.map((classroom) => classroom.students.length ).reduce((r, n) => r + n);

  return (
    <GridExplicit>
      <Box>
        <BoxSubtitle>Jumlah Kelas</BoxSubtitle>
        <BoxNumber>{classrooms.length == 0 ? '' : classrooms.length}</BoxNumber>
      </Box>
      <Box>
        <BoxSubtitle>Jumlah Murid</BoxSubtitle>
        <BoxNumber>{studentsCount}</BoxNumber>
      </Box>
      <Box style={{textAlign: 'left'}}>
        <BoxSubtitle style={{fontSize: '0.8em', marginBottom: '.5em'}}>Kehadiran Hari ini (Total)</BoxSubtitle>
        <TodayTotalAttendance />
      </Box>
      <Box height="300px">
        <BoxSubtitle style={{fontSize: '0.8em', marginBottom: '1em', position: 'absolute'}}>Riwayat Kehadiran</BoxSubtitle>
        <AttendanceChart />
      </Box>
      <Box>
        <BoxSubtitle style={{fontSize: '0.8em', textAlign: 'left'}}>Kehadiran Hari Ini (Per Kelas)</BoxSubtitle>
        <TodayClassAttendance style={{marginTop: '50px'}} />
      </Box>
    </GridExplicit>
  )
}

export default DashboardMain;
