import React, { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import loadable from '@loadable/component'

import { useQuery } from '@apollo/client'

import { ApolloProvider } from '@apollo/client'
import { client } from '../../_includes/forstok'

import SingleLayout from '../../_layouts/single-layout'
import PopupContainer from '../../components/popup/popup.container'
import LinkComponent from '../../components/link/link.component'
import DropdownComponent from '../../components/dropdown/dropdown.component'
import ButtonComponent from '../../components/button/button.component'
import InputComponent from '../../components/input/input.component'
import IconComponent from '../../components/icon/icon.component'
import TeacherAttendanceList from './partials/list'
import GridLayout from '../../_layouts/grid-layout'
import { ButtonGroup, LinkContainer, FilterContainer, SearchFilterContainer, FilterWrapper } from '../../assets/stylesheets/shares.styles.jsx'
import { LogoContainer, SearchSalesOrderWrapper } from './teacher_attendances.styles'
import { getStorage, setStorage } from '../../assets/javascripts/function'
import moment from '../../assets/javascripts/moment-range'

const FilterDateRangePartial = loadable(() => import('./partials/filter.time'))
const FilterPartial = loadable(() => import('./partials/filter'))

const TeacherAttendanceGridIndex = () => {
  let location = useLocation();
  const params = new URLSearchParams(location.hash),
        hashStartDate = params.get('#startDate'),
        hashEndDate = params.get('endDate'),
        hashClassroomId = params.get('classroomId'),
        hashStatusId = params.get('statusId');
  // console.log(getStorage('filterDateRangeBySalesOrders'))
  let initDateRange;
  if(hashStartDate && hashEndDate) {
    initDateRange = {startDate: hashStartDate, endDate: hashEndDate}
  } else {
    setStorage('filterDateRangeBySalesOrders', JSON.stringify({rangeDate: 'today'}))
    initDateRange = {startDate: moment().clone().format('YYYY-MM-DD'), endDate: moment().clone().format('YYYY-MM-DD')}
  }

  let classroomId = hashClassroomId ? {classroomId: parseInt(hashClassroomId)} : {};
  let statusId = hashStatusId ? {statusId: hashStatusId} : {};
  const [ isOpenPopup, setOpenPopup ] = useState(false)
  const [ isOpenPopupContainer, setOpenPopupContainer ] = useState(false)
  const [ modePopup, setModePopup ] = useState('')
  const [ dataIdPopup, setDataIdPopup ] = useState('')
  const [ dataDetailPopup, setDataDetailPopup ] = useState('')
  const [ isForceUpdate, setForceUpdate ] = useState(false)
  const [ dateRange, setDateRange ] = useState(initDateRange)
  const [ groupFilter, setGroupFilter ] = useState({...classroomId, ...statusId})

  const resetByOption = useCallback((opt, callback, noScroll) => {
    console.log('hello there!')
    if(callback) callback()
    let groupFilter = getStorage('filterBySalesOrders'),
    timeFilter = getStorage('filterDateRangeBySalesOrders');

    setDateRange(timeFilter)
    setGroupFilter(groupFilter)
  })
  const evOpenPopup = (e) => {
    const buttonEl = e.target.closest('a')
    if (!buttonEl) return
    const popup = buttonEl.getAttribute('data-popup')
    const dataId = buttonEl.getAttribute('data-id')
    const dataDetail = buttonEl.getAttribute('data-detail')

    const props = {
      mode: popup,
      id: dataId,
      detail: JSON.parse(dataDetail)
    }
    evTooglePopup(props)
  }

  const evTooglePopup = (props) => {
    if(isOpenPopup) {
      setOpenPopup(false)
      setModePopup('')
      setDataIdPopup('')
      setDataDetailPopup('')
      setOpenPopupContainer(false)
      document.getElementsByTagName("BODY")[0].classList.remove("is-immuned")
    }else {
      setOpenPopup(true)
      setModePopup(props.mode)
      setDataIdPopup(props.id)
      setDataDetailPopup(props.detail)
      setOpenPopupContainer(true)
      document.getElementsByTagName("BODY")[0].classList.add("is-immuned")
    }
  }

  const evCloseDropdown = (e) => {
    const containerEl = e.target.closest('._refContainer'),
    isOpen = containerEl.classList.contains('is-shown')
    console.log(containerEl);
    console.log(e.target);
    if(isOpen) {
      const ContainerRef = document.getElementsByClassName('_refContainer is-shown')
      if(ContainerRef.length) {
        for(var i = 0; i < ContainerRef.length; i++){
          ContainerRef[i].classList.remove('is-shown')
        }
      }
    }
    document.getElementsByTagName("BODY")[0].classList.remove("is-immuned")
  }

  return (
    <ApolloProvider client={client}>
      <GridLayout>
        <FilterContainer>
          <SearchFilterContainer>
            <FilterWrapper>
              <FilterDateRangePartial localStorageName='filterDateRangeBySalesOrders' isForceUpdate={isForceUpdate} setForceUpdate={setForceUpdate} resetByOption={resetByOption} />
              <LinkComponent style={{lineHeight: '2em'}} to='#' mode='clearlink' onClick={() => {
                let groupFilter = getStorage('filterBySalesOrders');
                console.log(dateRange);

                          window.location.href = `http://genio.forstok.com:3003/teacher_attendance?start_date=${dateRange['startDate']}&end_date=${dateRange['endDate']}`;
                          }
                        }>Export</LinkComponent>
            </FilterWrapper>
          </SearchFilterContainer>
        </FilterContainer>
        <TeacherAttendanceList evOpenPopup={evOpenPopup} dateRange={dateRange} groupFilter={groupFilter} />
        {/* <PopupContainer isOpen={isOpenPopup} isOpenContainer={isOpenPopupContainer} mode={modePopup} dataId={dataIdPopup} evTooglePopup={evTooglePopup} dataDetail={dataDetailPopup}/> */}
      </GridLayout>
    </ApolloProvider>
  )
}

export default TeacherAttendanceGridIndex;
