import React from 'react'
import { useQuery } from '@apollo/client'

import { ContentLoaderTableOnly } from '../../../components/loader/loader.component'
import TableComponent from '../../../components/table/table.component'
import LabelComponent from '../../../components/label/label.component'
import LinkComponent from '../../../components/link/link.component'

import { ActionContainer, SortingContainer } from '../admin.styles'

import { GET_TEACHER_ATTENDANCE_LIST } from '../../../config/graphql'

const TeacherAttendanceList = ({ dateRange, groupFilter }) => {
    console.log({ variables: {...dateRange, ...groupFilter} })
    // const { loading, data } = useQuery(GET_TEACHER_ATTENDANCE_LIST, { variables: {...dateRange, ...groupFilter} });
    // console.log(data);
    const dataTeacherAttendance = [];
    // const dataTeacherAttendance = data?.teacherAttendances || [];

    // if (loading && !dataTeacherAttendance.length) {
    if (true) {
      return <div />
      // return <ContentLoaderTableOnly />
    } else {
      var idx = 0;
      return <div>
        <TableComponent mode='custom' template='1fr 3fr 4fr 2fr 2fr'>
          <div aria-label='head'>
            <div>
              <SortingContainer>No</SortingContainer>
            </div>
            <div>
              <SortingContainer>Tanggal</SortingContainer>
            </div>
            <div>
              <SortingContainer>Nama</SortingContainer>
            </div>
            <div>
              <SortingContainer>Datang</SortingContainer>
            </div>
            <div>
              <SortingContainer>Pulang</SortingContainer>
            </div>
          </div>
          <div aria-label='body'>
            {dataTeacherAttendance.map((teacherAttendance) => {
                idx++;
                return (
                  <div key={idx}>
                    <div>
                      <LabelComponent mode='tagredmobile'>NO</LabelComponent>
                      {idx}
                    </div>
                    <div>
                      <LabelComponent mode='tagredmobile'>Tanggal</LabelComponent>
                      {(new Date(Date.parse(teacherAttendance.date))).toLocaleDateString('id', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </div>
                    <div>
                      <LabelComponent mode='tagredmobile'>Nama</LabelComponent>
                      {(teacherAttendance.teacher.name)}
                    </div>
                    <div>
                      <LabelComponent mode='tagredmobile'>Datang</LabelComponent>
                      {(teacherAttendance.createdAt ? new Date(Date.parse(teacherAttendance.createdAt)).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" }) : '-')}
                    </div>
                    <div>
                      <LabelComponent mode='tagredmobile'>Pulang</LabelComponent>
                      {(teacherAttendance.checkoutAt ? new Date(Date.parse(teacherAttendance.checkoutAt)).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" }) : '-')}
                    </div>
                  </div>
                )
            })}
          </div> 
        </TableComponent>
      </div>
    }
}

export default TeacherAttendanceList;