
import styled, { css } from 'styled-components'
/* style */
const GlobalInputStyles = css`
  position: relative;
  padding: 9px 16px;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 4px;
  background-color: #fff;
  font-size: 13px;
  height: auto;
  max-height: 36px;
  width: 100%;
  &:focus {
    // border-color: #fc5c64;
    outline: none;
  }
  &::placeholder {
    font-style: italic;
  }
`
/* component */
export const SelectContainer = styled.select`
${GlobalInputStyles}
`
/* component */