import React from 'react'

import PopupComponent from '../../../components/popup/popup.component'

import { ListBodyContainer, ListContainer, ListContentContainer } from '../admin.styles'

export const PopupViewScores = ({ body, height, width, isOpen, isOpenContainer, evTooglePopup, dataDetail }) => {
  console.log(dataDetail);
  return(
    <PopupComponent body={body} height={height} width={width} isOpen={isOpen} isOpenContainer={isOpenContainer} evTooglePopup={evTooglePopup}>
      <div aria-label='head'>{dataDetail.length} Nilai</div>
      <div aria-label='body'>
      <ListBodyContainer>
        {dataDetail.map((attendance, _) => {
          return (
            <ListContainer key={attendance.id}>
              <ListContentContainer>{attendance.student.classroom.name} - {attendance.student.name} ({attendance.status.name})</ListContentContainer>
            </ListContainer>
          )
        })}
      </ListBodyContainer>
      </div>
    </PopupComponent>
  );
}
