import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { DropDownContainer, DropDownTitle, DropDownSubTitle, DropDownWrapper, DropDownOverlayWrapper, DropDownControl, DropDownBody, DropDownAction, DropdownControlWrapper } from '../dropdown/dropdown.styles'

class DropDownComponent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired, 
    title: PropTypes.string, 
    subTitle: PropTypes.string, 
    externalWidth: PropTypes.string, 
    externalMinWidth: PropTypes.string, 
    internalWidth: PropTypes.string, 
    area: PropTypes.string, 
    openPosition: PropTypes.string, 
    placement: PropTypes.string,
    top: PropTypes.string,
    onClick: PropTypes.func,
    alias: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.ButtonRef = React.createRef()
    this.evToogleDropdown = this.evToogleDropdown.bind(this)
  }

  // componentDidUpdate() {
  //   ReactTooltip.rebuild()
  // }

  evToogleDropdown = (e) => {
    const buttonEl = e.target.closest(`.${this.ButtonRef.current.classList[0]}`)
          
    if (!buttonEl) return
    const containerEl = buttonEl.closest('._refContainer'),
      overlayEl = containerEl.querySelector(`._refDropdownOverlay`),
      isOpen = containerEl.classList.contains('is-shown')
    
    if(!isOpen) {
      const ContainerRef = document.getElementsByClassName('_refContainer is-shown')
      if(ContainerRef.length) {
        for(var i = 0; i < ContainerRef.length; i++){
          ContainerRef[i].classList.remove('is-shown')
        }
      }  
      if (this.props.onClick && typeof(this.props.onClick) == "function") this.props.onClick()
    }
    this.setState({isOpen: !isOpen}, () => {
      const bodyEl = document.getElementsByTagName("BODY")[0],
        refScrollContainerEl = document.querySelector('._refScrollContainer')
      if(!isOpen) {
        overlayEl.style.display = 'block'
        bodyEl.classList.add("is-immuned")
        // refScrollContainerEl && (refScrollContainerEl.style.overflow = "hidden")
        refScrollContainerEl && (refScrollContainerEl.firstChild.style.overflowY = "hidden")
        containerEl.classList.add('is-shown')
      }
      else {
        overlayEl.style.display = 'none'
        bodyEl.classList.remove("is-immuned")
        // refScrollContainerEl && (refScrollContainerEl.style.overflow = "auto")
        refScrollContainerEl && (refScrollContainerEl.firstChild.style.overflowY = "hidden")
        containerEl.classList.remove('is-shown')
      }
    })
  }

  evCloseDropdown = (e) => {
    const ContainerRef = document.getElementsByClassName('_refContainer is-shown')
    if(ContainerRef.length) {
      for(var i = 0; i < ContainerRef.length; i++){
        const overlay = ContainerRef[i].querySelector(`._refDropdownOverlay`)
        ContainerRef[i].classList.remove('is-shown')
        overlay.style.display = 'none'
      }
    }  
    const bodyEl = document.getElementsByTagName("BODY")[0],
        refScrollContainerEl = document.querySelector('._refScrollContainer')
    bodyEl.classList.remove("is-immuned")
    refScrollContainerEl.style.overflow = "auto"
    refScrollContainerEl.firstChild.style.overflowY = "hidden"
  }

  render() {
    const { children, title, subTitle, externalWidth, externalMinWidth, internalWidth, area, openPosition, top, type, dataQaId, alias, placement, bottom} = this.props
    const { isOpen } = this.state
    const ControlChildrenFilter = children.filter(child => (child !== null && child !== '') && child.props['aria-label'] === 'control'),
      BodyChildrenFilter = children.filter(child => (child !== null && child !== '') && child.props['aria-label'] === 'body'),
      ActionChildrenFilter = children.filter(child => (child !== null && child !== '') && child.props['aria-label'] === 'action'),
      DropDownSubTitleEl = (subTitle) ? <DropDownSubTitle>{subTitle}</DropDownSubTitle> : null,
      DropDownTitleEl = (title) ? <DropDownTitle>{title} {DropDownSubTitleEl}</DropDownTitle> : null,
      DropDownControlEl = (type === 'hover') ? <DropdownControlWrapper>
                                                <DropDownControl className='_refDropdownControl' >{ControlChildrenFilter[0].props.children}</DropDownControl>
                                                <DropDownControl className='_refDropdownControlMobile' ref={this.ButtonRef} onClick={this.evToogleDropdown} >{ControlChildrenFilter[0].props.children}</DropDownControl>
                                                </DropdownControlWrapper> : <DropDownControl ref={this.ButtonRef} onClick={this.evToogleDropdown} >{ControlChildrenFilter[0].props.children}</DropDownControl>,
      DropDownBodyEl = (<DropDownBody>{BodyChildrenFilter[0].props.children}</DropDownBody>),
      DropDownActionEl = (ActionChildrenFilter.length) ? (<DropDownAction position={ActionChildrenFilter[0].props.position} className={ActionChildrenFilter[0].props.className}>{ActionChildrenFilter[0].props.children}</DropDownAction>) : null,
      OpenClass = isOpen ? 'is-shown' : '' 
    return (
      <DropDownContainer className={`_refContainer ${OpenClass} ${type ? `_refContainer${type}` : ``}`} width={externalWidth} minWidth={externalMinWidth} area={area} top={top} type={type} data-qa-id={dataQaId} alias={alias} openPosition={openPosition} placement={placement} bottom={bottom}>
        {DropDownControlEl}
        <DropDownOverlayWrapper className='_refDropdownOverlay' onClick={this.evCloseDropdown}/>
        <DropDownWrapper width={internalWidth} openPosition={openPosition} placement={placement} bottom={bottom} top={top} className='_refDropdownWrapper'>
          {DropDownTitleEl}
          {DropDownBodyEl}
          {DropDownActionEl}
        </DropDownWrapper>
      </DropDownContainer>
    )
  }
}
export default DropDownComponent
