import styled, { css } from 'styled-components'
import IconClose from '../../assets/images/icons/close.svg'

import IconFilter from '../../assets/images/icons/filter.svg'
import IconCalendar from '../../assets/images/icons/calendar.svg'

/* style */
const GlobalButtonStyles = css`
  display: inline-grid;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  background-color: transparent;
`
const DefaultButtonStyles = css`
  height: 28px;
  cursor: pointer;;
  position: relative;
`
const IconStyles = css`
  display: inline-block;
  pointer-events: none;
`
const BigDefaultStyles = css`
  border: 1px solid #ccc;
  height: 36px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`
const RedBigDefaultStyles = css`
  background: #fc5c64;
  color: #fff;
  height: 34px;

  &:hover {
    background: #ec5b62;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`
const SmallRedStyles = css` 
  background: #fc5c64;
  color: #fff;
  font-size: 12px;
  height: 28px;
  @media (min-width: 768px) {
    height: auto;
    font-size: 10px;
  }
`
const RedStyles = css` 
  background: #fc5c64;
  color: #fff;
  min-width: 66px;
  &:hover {
    background: #ec5b62;
  }
`
const WhiteStyles = css` 
  background: transparent;
  border: 1px solid rgba(45,60,72,.12);
  color: #000;
  min-width: 66px;
  &:hover {
    background: #f7f7f7;
  }
`
const GreyStyles = css`
  background-color: #f6f6f6;
  color: #b8b8b8;
  font-size: 80%;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  padding: 4px 3px;
  border-radius: 2px;
  line-height: 0;
`
const clearLinkStyles = css`
  color: #429ddf;
  height: auto;
  margin: 0;
  padding 0; 
  border: 0;
  justify-self: start;
`;
const clearUnderlineLinkStyles = css`
  text-decoration: underline;
`;
const DisabledStyles = css`
  &:disabled {
    pointer-events: none;
    opacity: .7;
    cursor: default;
  }
`
/* style */

/* function */
const getButtonModifiedStyled = ({mode, isIndicatorArrow, marginRight, isShown, ...props}) => {
  let modifiedStyled = ""
  if(isShown === false) return `display:none;`
  if(mode === 'clearlinkunderline') {
    modifiedStyled +=  clearLinkStyles;
    modifiedStyled +=  clearUnderlineLinkStyles;
  }
  else if(mode === 'clearlink') modifiedStyled += clearLinkStyles;
  else if(mode === 'smallred') modifiedStyled += SmallRedStyles;
  else if(mode === 'roundclose') modifiedStyled += `
    &:before { 
      content: url(${IconClose});
      width: 12px;
      ${IconStyles}
    }  
  `
  else modifiedStyled += DefaultButtonStyles;

  if(mode === 'red') modifiedStyled += RedStyles;
  if(mode === 'white') modifiedStyled += WhiteStyles;
  if(mode === 'bigdefault') modifiedStyled += BigDefaultStyles;
  if(mode === 'redbigdefault') modifiedStyled += RedBigDefaultStyles;
  if(mode === 'grey') modifiedStyled += GreyStyles;

  if(props.iconLeft) {
    modifiedStyled += ` padding-left: 35px; `
    if(props.iconLeft === 'filter') modifiedStyled += `
      &:before { 
        content: url(${IconFilter}); 
        position: absolute;
        left: 12px;
        top: 8px;
        width: 14px;
        height: 16px;
      }
    `
    else if(props.iconLeft === 'calendar') modifiedStyled += `
      &:before { 
        content: url(${IconCalendar}); 
        position: absolute;
        left: 12px;
        top: 9px;
        width: 14px;
        height: 16px;
      }
    `
    
    if(props.fontSize) modifiedStyled += `
      font-size: ${props.fontSize};
    `
  }
  if(isIndicatorArrow) modifiedStyled += ` padding-right: 30px; `
  if(marginRight) modifiedStyled += `
    margin-right: ${marginRight} !important;
  `
  return modifiedStyled
}
/* function */

/* component */
export const ButtonContainer = styled.button.attrs(props => ({
    type: (props.type) ? props.type : 'button' 
  }))`
  ${GlobalButtonStyles}
  ${getButtonModifiedStyled}
  ${DisabledStyles}
`
export const IndicatorsArrowIconSvg = styled.svg`
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  position: absolute;
  right: 8px;9c
`
/* component */