import Cookies from 'universal-cookie';
const cookies = new Cookies()

class Auth {
  constructor() {
    this.authenticated = (cookies.get('admin_token')) ? true : false;
  }

  login(cb){
    this.authenticated = true;
    cb()
  }

  logout(cb) {
    this.authenticated = false;
    cb()
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();