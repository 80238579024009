import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'

import PopupComponent from '../../../components/popup/popup.component'
import { BackgroundContainer, LoginContainer, SideContainer, LogoContainer, LoginFormContainer, RememberContainer, CopyContainer, ErrorContainer } from '../../../pages/login/login.styles'
import HeadingComponent from '../../../components/heading/heading.component'
import LabelComponent from '../../../components/label/label.component'
import InputComponent from '../../../components/input/input.component'
import SelectComponent from '../../../components/select/select.component'
import { SelectContainer } from '../../../components/select/select.styles'

import CheckboxComponent from '../../../components/checkbox/checkbox.component'
import LinkComponent from '../../../components/link/link.component'

import { ListBodyContainer, ListContainer, ListContentContainer } from '../admin.styles'
import '../autocompleter.css';
import { GET_STUDENT_LIST } from '../../../config/graphql'

import autocomplete from 'autocompleter';

export const PopupCreateStudentAttendance = ({ body, height, width, isOpen, isOpenContainer, evTooglePopup, dataDetail }) => {
  console.log(dataDetail);
  const { loading, data } = useQuery(GET_STUDENT_LIST);
  console.log(data);
  const users = data?.students || [];
  console.log(users);

  useEffect(() => {
    var input = document.getElementById('input-name');

    autocomplete({
        input: input,
        minLength: 1,
        fetch: function(text, update) {
            text = text.toLowerCase();
            // you can also use AJAX requests instead of preloaded data
            var suggestions = users
              .filter(n => n.name.toLowerCase().includes(text) && (n.kind == 'murid'))
              .map(function (n) { return { label: `${n.name} - Kelas ${n.classroom.name}`, value: n.id }; });
            update(suggestions);
        },
        onSelect: function(item) {
            input.value = item.label;
            input.setAttribute('data-user-id', item.value);
        },
        click: e => e.fetch(),
        keyup: e => e.fetch()
    });
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('helllo world!')
    console.log(document.getElementById('current-user-id').dataset.id)
    var xhr = new XMLHttpRequest();
    xhr.open("POST", 'http://genio.forstok.com:3003/student_attendance', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
        if (this.readyState != 4) return;
    
        if (this.status == 200) {
            var data = JSON.parse(this.responseText);
    
            console.log(data);
            // we get the returned data
        }
        window.location.reload(false);
    
        // end of state change: it can be after some time (async)
    };
    
    xhr.send(JSON.stringify({
      "student_id": document.getElementById('input-name').dataset.userId,
      "user_id": document.getElementById('current-user-id').dataset.id,
      "status_id": document.getElementById('input-status-id').selectedOptions[0].value,
    }));
  };
  return(
    <PopupComponent body={body} height={height} width={width} isOpen={isOpen} isOpenContainer={isOpenContainer} evTooglePopup={evTooglePopup}>
      <div aria-label='head'>{dataDetail.length} Absen Manual</div>
      <div aria-label='body'>
        <LoginFormContainer onSubmit={handleSubmit}>
            <LabelComponent mode="block">
                Nama
            </LabelComponent>
            <InputComponent id='input-name' type="nama" mode="large" name="nama" required />
            <LabelComponent mode="block">
                Status
            </LabelComponent>
            <SelectContainer id="input-status-id" name="status_id">
              <option value="1">Hadir</option>
              <option value="5">Sakit</option>
              <option value="6">Izin</option>
              <option value="3">Alfa</option>
            </SelectContainer>
            {/* <InputComponent mode="large" required disabled value="Hadir"/> */}
            <RememberContainer>
            </RememberContainer>
            <InputComponent id='button-login' type="submit" mode="large" value="Absen Manual"/>
        </LoginFormContainer>
      </div>
    </PopupComponent>
  );
}
