import styled, { css } from 'styled-components'

/* style */
const BorderPopupStyles = css`
  border-bottom: 1px solid #e4e5e5;
`
/* style */

/* function */
const getPopupModifiedStyled = (props) => {
  if(props.isOpen) return `
    & ${PopupOverlay}, & ${PopupContainer} {
      display: block;
    }
  `
}
const getPopupWrapperModifiedStyled = (props) => {
  if(props.isOpen) return `
    top: 0;
    transition: top .75s;
  `
}
const getPopupBodyModifiedStyled = (props) => {
  let modifiedStyles = ``
  if(props.mode === 'clear') modifiedStyles += `
    display: block;
    padding: 0;
  `
  if(props.height) modifiedStyles += `
    height: auto;
    max-height: ${props.height};
    overflow: auto;
  `
  if(props.width) modifiedStyles += `
    width: ${props.width};
  `
  return modifiedStyles
}
/* function */

/* component */
export const Popup = styled.div`
`
export const PopupPanel = styled.div`
  ${getPopupModifiedStyled}
`
export const PopupOverlay = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  background-color: rgba(0,0,0,.15);
`
export const PopupContainer = styled.section`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
`
export const PopupWrapper = styled.section`
  max-width: 1000px;
  height: auto;
  max-height: 100%;
  transform: translate(-50%);
  left: 50%;
  top: -100%;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.22);
  background-color: #fff;
  border-radius: 0;
  transition: top .5s;
  width: auto;
  min-width: 600px;
  padding: 0;
  position: relative;
  display: inline-grid;
  grid-auto-flow: row;
  button[mode=roundclose] {
    position: absolute; 
    top: 15px;
    right: 5px;
  }
  ${getPopupWrapperModifiedStyled}
`
export const PopupHeader = styled.div`
  padding: 15px;
  ${BorderPopupStyles}
  font-size: 16px;
  font-weight: 600;
  min-height: 50px;
`
export const PopupBody = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 15px 30px;
  min-height: 20vh;
  height: 350px;
  overflow: scroll;
  ${BorderPopupStyles}
  ${getPopupBodyModifiedStyled}
`
export const PopupFooter = styled.div`
  display: inline-grid;
  padding: 15px;
  grid-auto-flow: column;
  aside {
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
  }

`
/* component */