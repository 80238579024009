import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { ApolloProvider } from '@apollo/client'
import { client } from '../../_includes/forstok'

import GridLayout from '../../_layouts/grid-layout'
// import PopupContainer from '../../components/popup/popup.container'
// import LinkComponent from '../../components/link/link.component'
// import AttendanceList from './partials/list'
import DashboardMain from './partials/main'

const DashboardGridIndex = () => {
  return (
    <ApolloProvider client={client}>
      <GridLayout>
        <DashboardMain />
      </GridLayout>
    </ApolloProvider>
  )
}

export default DashboardGridIndex;
