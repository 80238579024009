import styled, { css } from 'styled-components'

export const ButtonGroup = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  box-shadow: none;
  background-color: #F5F5F5;
  padding: 2px 0px;
  > button {
    width: auto;
    min-width: auto;
    letter-spacing: 0.6px;
    line-height: 15px;
    border-radius: 0;
  }
  > button , ._refContainer {
    border-right: 1px solid #E0E0E0;
    &:last-child{
      border-right: 0;
    }
  }
  ._refContainer {
    max-width: 200px;
  }
`

const getDropdownListModFunc = ({mode}) => {
  let modStyle = ``
  if(mode === 'no-border') modStyle +=`
    border-bottom: none;
  `
  return modStyle
}

const getDropdownItemModFunc = ({mode}) => {
  let modStyle = ``
  if (mode === 'categories') modStyle +=`
    grid-template-columns: 22px auto;
    min-height: 33px;
    align-items: center;
    grid-gap: 0;
    >label {
      cursor: pointer;
      text-transform: capitalize;
    }
  `
  return modStyle
}

const getLinkContModFunc = ({align}) => {
  let modStyle = ``
  if(align === 'right') modStyle +=`
    a {
      text-align: right;
    }
  `
  return modStyle
}

export const DropdownTitle = styled.div`
  padding: 8px 4px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  border-bottom: 1px solid #e9ebec;
  color: #505050;
  label, a {
    font-size: 14px !important;
  }
  label {
    font-weight: 600;
  }
  i {
    font-weight: 400;
    font-style: normal;
  }
`


export const DropdownList = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
  max-height: 44vh;
  overflow: auto;
  @media only screen and (min-width: 1440px) {
    max-height: 50vh;
  }
  @media only screen and (min-width: 1680px) {
    max-height: 54vh;
  }

  > li {
    padding: 8px 4px;
    border-bottom: 1px solid #e9ebec;
    font-size: 14px;
    ${getDropdownListModFunc}
  }
`

export const DropdownItem = styled.li`
  display: inline-grid;
  width: 100%;
  grid-auto-flow: column;
  grid-template-columns: 100px auto;
  grid-gap: 10px;
  min-height: 48px;
  ${getDropdownItemModFunc}
`

export const DropdownAction = styled.div`
  padding-top: 20px;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-gap: 4px;
`

export const FilterList = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
`

export const FilterListTitle = styled.label`
  font-weight: 600;
  padding-top: 8px;
`

export const FilterItem = styled.li`
  > input {
    display: none;
  }
  > label {
    display: block;
    float: left;
    padding: 8px 16.5px;
    margin-right: 8px;
    margin-bottom: 8px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 4px;
    }
    cursor: pointer;
  }
  > input:checked + label {
    background: #F5F5F5;
    border: 2px solid #F5F5F5;
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 600;
    padding: 6px 14px;
  }
`

export const FilterWrapper = styled.section`
  display: inline-block;
  padding-left: 0;
  width: 100%;
  margin-top: 10px;
  ${ButtonGroup} {
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  > section {
    width: 48.35% !important;
  }
  @media only screen and (max-width: 1023px) {
    > section {
      display: block;
      float: left;
      margin-right: 9px;
      &:nth-of-type(even){
        margin-right: 0;
      }
      &:nth-of-type(n+3){
        margin-top: 10px;
      }
      > section >button, >div >button {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        max-width: 100%;
      }
    }
  }
  @media only screen and (min-width: 375px) {
    ${ButtonGroup} {
      height: 32px;
    }
  }
  @media only screen and (min-width: 425px) {
    > section {
      width: 48.725% !important;
    }
  }
  @media only screen and (min-width: 768px) {
    > section {
      width: 49.3225% !important
    }
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 0;
    padding-left: 8px;
    grid-auto-flow: column;
    grid-gap: 10px;
    display: grid;
    ${ButtonGroup} {
      margin-bottom: 0;
    }
    > section {
      width: auto !important;
    }
  }
`

export const FilterContainer = styled.article`
  > div {
    justify-content: left;
  }
`

export const SearchFilterContainer = styled.div`
  // padding: 16px .625em;
  width: 100%;
  margin-bottom: 16px !important;
  // @media only screen and (min-width: 375px) {
  //   padding: 16px;
  // }
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-auto-flow: column;
    justify-content: left;
  }
`


export const LinkContainer = styled.article`
  display: inline-grid;
  grid-auto-flow: row;
  grid-gap: 12px;
  width: 100%;
  ${getLinkContModFunc}
  a {
    font-weight: 400;
    font-size: 14px;
    color: #505050;
    text-decoration: none;
  }
  hr {
    height: .75px;
    margin: 0px -14px;
    border-bottom: 0.75px solid #DEE0E0;
    opacity: .16;
    box-shadow: none;
  }
`