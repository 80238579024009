import React from 'react'
import { withRouter } from 'react-router-dom'

import { forstokCookies } from '../forstok'
import Auth from '../../pages/login/auth/auth'

import LinkComponent from '../../components/link/link.component'

import { HeaderContainer, LogoContainer, TextContainer, SideNavContainer} from '../header/header.styles'

const HeaderInclude = props => {
  const {history} = props

  const handleLogout = () => {
    if(process.env.NODE_ENV === 'development') {
      forstokCookies.remove('admin_token',{ path: '/' })
    }else {
      forstokCookies.remove('admin_token',{ path: '/' })
    }
    Auth.logout(() => { history.push('/')  })
  }

  return (
    // <HeaderContainer>
    //   <TextContainer>Guru</TextContainer>
    //   <SideNavContainer>
    //     <LinkComponent id='link-logout' mode='clearlink' to='#' onClick={handleLogout}>Logout</LinkComponent>
    //   </SideNavContainer>
    // </HeaderContainer>
    <LinkComponent id='link-logout' mode='clearlink' to='#' onClick={handleLogout}>Logout</LinkComponent>
  )
}

export default withRouter(HeaderInclude)