import styled, { css } from 'styled-components'

import { ButtonGroup } from '../../assets/stylesheets/shares.styles'

export const SearchSalesOrderWrapper = styled.div`
  display: grid;
  grid-template-columns: 148px 1fr;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1280px) {
    width: 300px;
  }
  @media only screen and (min-width: 1440px) {
    width: 350px;
  }
  @media only screen and (min-width: 1680px) {
    width: 400px;
  }

  i {
    position: absolute;
    top: 8px;
    right: 13px;
    z-index: 1;
  }
  input {
    padding-right: 30px;
    padding-left: 7px;
    border-radius: 0 10px 10px 0;
    &:not([type="submit"]):focus {
      border: none;
    }
  }
  & ${ButtonGroup} {
    width: 148px;
    height: 32px;
    border-radius: 10px 0 0 10px;
    ._refContainer {
      border-right: 1px solid #E0E0E0;
    }
  }
  button {
    width: 143px;
  }
`
