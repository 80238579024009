import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ProtectedRoute } from './pages/login/auth/protected.route';
import LoginIndex from './pages/login/index'
import AdminIndex from './pages/admin/index'
import TeacherAttendanceGridIndex from './pages/teacher_attendances/grid-index'
import AttendanceGridIndex from './pages/attendances/grid-index'
import DashboardGridIndex from './pages/dashboard/grid-index'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <Switch>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={AdminIndex}/> */}
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={LoginIndex}/>
        <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/dashboard/teacher_attendance`} component={TeacherAttendanceGridIndex}/>
        <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/dashboard/attendance`} component={AttendanceGridIndex}/>
        <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/dashboard`} component={DashboardGridIndex}/>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/dashboard/admin`} component={AdminIndex}/> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
