import Cookies from 'universal-cookie'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-boost'
import { setContext } from 'apollo-link-context'

export const forstokCookies = new Cookies()
const forstokHttpLink = createHttpLink({uri: process.env.REACT_APP_API_URL + '/teacher_graphql'})
const forstokAuthLink = setContext((_, { headers }) => {
  const forstokToken = forstokCookies.get('admin_token')
  return {
    headers: {
      ...headers,
      authorization: forstokToken ? `Bearer ${forstokToken}` : "",
    }
  }
})

export const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const client = new ApolloClient({
  link: forstokAuthLink.concat(forstokHttpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})