import React, { Component } from 'react'

import ButtonComponent from '../button/button.component'

import { PopupPanel, PopupOverlay, PopupContainer, PopupWrapper, PopupHeader, PopupBody, PopupFooter } from './popup.styles'

class PopupComponent extends Component {
  render() {
    const { children, body, height, width, isOpen, isOpenContainer, evTooglePopup } = this.props
    const HeadChildrenFilter = children ? children.filter(child => child.props['aria-label'] === 'head') : [],
      BodyChildrenFilter = children ? children.filter(child => child.props['aria-label'] === 'body') : [],
      FootChildrenFilter = children ? children.filter(child => child.props['aria-label'] === 'foot') : null
    return (
      <PopupPanel isOpen={isOpen}>
        <PopupOverlay></PopupOverlay>
        <PopupContainer>
          <PopupWrapper isOpen={isOpenContainer}>
            <ButtonComponent mode='roundclose' onClick={evTooglePopup}></ButtonComponent>
            <PopupHeader>{HeadChildrenFilter ? HeadChildrenFilter[0].props.children : ''}</PopupHeader>
            <PopupBody mode={body} width={width} height={height}>{BodyChildrenFilter ? BodyChildrenFilter[0].props.children : ''}</PopupBody>
            <PopupFooter>
              <aside>
                <ButtonComponent mode='white' onClick={evTooglePopup}>Cancel</ButtonComponent>
                {FootChildrenFilter.length ? FootChildrenFilter[0].props.children : ''}
              </aside>
            </PopupFooter>
          </PopupWrapper>
        </PopupContainer>
      </PopupPanel>
    )
  }
}

export default PopupComponent