import React from 'react'
import { useLocation } from 'react-router-dom'
import { forstokCookies } from '../_includes/forstok'
import Auth from '../pages/login/auth/auth'
import LinkComponent from '../components/link/link.component'
import HeaderInclude from '../_includes/header/header-include'

// import HeaderInclude from '../_includes/header/header-include'
import { AdminContainer, NavContainer, LinkContainer, LayoutNavContainer, LayoutContentContainer } from './admin.styles'

import { GridContainer, GridHeader, GridSidenav, GridSidenavList, GridSidenavListItem, GridMain, GridFooter } from './layout.styles'
import { SideContainer, LogoContainer } from '../pages/login/login.styles'


import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER } from '../config/graphql'

const GridLayout = ({ children, ...props }) => {
  let location = useLocation();

  const { loading, data } = useQuery(GET_CURRENT_USER);
  const {history} = props
  const currentUser = data?.currentUser;

  // const handleLogout = () => {
  //   if(process.env.NODE_ENV === 'development') {
  //     forstokCookies.remove('admin_token',{ path: '/' })
  //   }else {
  //     forstokCookies.remove('admin_token',{ path: '/' })
  //   }
  //   Auth.logout(() => { history.push('/')  })
  // }

  return (
    <GridContainer>
      <GridHeader>
        <div id="current-user-id" data-id={currentUser?.id}>{currentUser?.name || ''}</div>
        <HeaderInclude />
      </GridHeader>
      <GridSidenav>
        <GridSidenavList>
          <GridSidenavListItem>
            <SideContainer style={{padding: '80px'}}>
              <LogoContainer></LogoContainer>
            </SideContainer>
          </GridSidenavListItem>
          <GridSidenavListItem>
            <LinkContainer to='/dashboard' isactive={location.pathname === '/dashboard' ? 1 : 0}>Dashboard</LinkContainer>
          </GridSidenavListItem>
          <GridSidenavListItem>
            <LinkContainer to='/dashboard/attendance' isactive={location.pathname === '/dashboard/attendance' ? 1 : 0}>Kehadiran Murid</LinkContainer>
          </GridSidenavListItem>
          {['32','526'].includes(currentUser?.id) &&
          <GridSidenavListItem>
            <LinkContainer to='/dashboard/teacher_attendance' isactive={location.pathname === '/dashboard/teacher_attendance' ? 1 : 0}>Kehadiran Guru</LinkContainer>
          </GridSidenavListItem>
          }
          {/* <GridSidenavListItem>Item Two</GridSidenavListItem>
          <GridSidenavListItem>Item Three</GridSidenavListItem>
          <GridSidenavListItem>Item Four</GridSidenavListItem>
          <GridSidenavListItem>Item Five</GridSidenavListItem> */}
        </GridSidenavList>
      </GridSidenav>
      <GridMain>
        {children}
      </GridMain>
        {/* <GridFooter>
          <div class="footer__copyright">&copy; 2023</div>
          <div class="footer__signature">Powered by Genio</div>
        </GridFooter> */}
    </GridContainer>
  )
}

export default GridLayout