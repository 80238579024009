import styled, { css } from 'styled-components'


const flex = css `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  // background-color: #648ca6;
`

export const GridExplicit = styled.div`
  display: grid;
  grid-gap: 20px;
  color: #444;
  margin: auto;
  max-width: 1112px;
`

export const Box = styled.div`
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 8%) 2px 4px 12px;
  border-radius: 10px;
  padding: 30px;
  font-size: 150%;
  text-align: center;
  
  :first-child {
    grid-column: 1;
    grid-row: 1;
  }

  :nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

  :nth-child(3) {
    grid-column: 3 / 30  ;
    grid-row: 1  ;
  }

  :nth-child(5) {
    grid-column: 1 / 7 ;
    grid-row: 2 ;
  }

  :nth-child(4) {
    grid-column: 7 / 30 ;
    grid-row: 2 ;
  }
`

export const BoxNumber = styled.div`
  font-size: 2.5em;
  line-height: 1em;
`
export const BoxSubtitle = styled.div`
  font-size: 1em;
`

export const ActionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  font-weight: 600;
`
export const SortingContainer = styled.span`
  position: relative;
  cursor: pointer;

  > i {
    position: absolute;
    top: 0;
    right: -20px;
    width: 10px;
    height: 10px;
  }
`